<template>
  <section class="page-wrap">
    <!-- 顶部操作栏 start -->
    <section class="header">
      <form-filter ref="refFormFilter" :pageParams="pageParams"></form-filter>
    </section>
    <!-- 顶部操作栏 end -->

    <!-- 列表数据 start -->
    <section class="content-wrap">
      <table-list v-loading="loadingData" :tableData="tableData"></table-list>
    </section>
    <!-- 列表数据 end -->

    <!-- 分页 start -->
    <section class="pagination">
      <el-pagination
        type="primary"
        :current-page="pageParams.page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageParams.pageSize"
        :total="total"
        @size-change="onHandleSizeChange"
        @current-change="onHandleCurrentChange"
        layout="total, sizes, prev, pager, next, jumper"
        background
      >
      </el-pagination>
    </section>
    <!-- 分页 end -->
  </section>
</template>
<script>
import FormFilter from "./modules/form-filter/index.vue";
import TableList from "./modules/table-list/index.vue";

export default {
  name: "LogisticsSupplyPrintLog",
  components: {
    FormFilter,
    TableList,
  },
  data() {
    return {
      loadingData: true,
      pageParams: {
        page: 1,
        pageSize: 50,
      },
      tableData: [],
      total: 0,
    };
  },
  mounted() {
    this.onInitData();
  },
  methods: {
    /**
     * 初始化数据
     */
    onInitData() {
      this.$refs.refFormFilter.onInitData();
    },
    /**
     * 修改了每页数据条数
     */
    onHandleSizeChange(val) {
      this.$refs.refFormFilter.onHandleSizeChange(val);
    },
    /**
     * 修改了页码
     */
    onHandleCurrentChange(val) {
      this.$refs.refFormFilter.onHandleCurrentChange(val);
    },
  },
};
</script>
<style lang="scss" scoped>
.page-wrap {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  box-sizing: border-box;
  padding: 10px;
  .form {
    padding: 13px 15px;
  }
  .content-wrap {
    flex-grow: 1;
    overflow-y: hidden;
  }
  .pagination {
    box-sizing: border-box;
    padding: 10px 20px;
  }
}
</style>
